import React from "react";
import Artykul from "../components/artykul";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import obrazekArtykulu from "../images/wsparcie-sprzedazy1.png";
import Lightbox from "../components/lightBox";
import ResponsiveEmbed from "react-responsive-embed";
import { Link } from "gatsby";
import { LiteYouTubeEmbed } from "react-lite-youtube-embed";
import Container from "../components/container";
import * as styles from "./index.module.css";

export const query = graphql`
  {
    zdjecie1: file(relativePath: { eq: "moje-zadania-cien.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zdjecie2: file(relativePath: { eq: "aktywnosc-handlowcow-cien.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zdjecie3: file(relativePath: { eq: "o-firmie-cien.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zdjecie4: file(relativePath: { eq: "finanse-cien.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zdjecie5: file(relativePath: { eq: "zadania-zdarzenia-cien.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zdjecie6: file(relativePath: { eq: "szanse2-cien.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zdjecie7: file(relativePath: { eq: "zegar-cien.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zdjecie8: file(relativePath: { eq: "lista-szanse-cien.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zdjecie9: file(relativePath: { eq: "raport-cien.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zdjecie10: file(relativePath: { eq: "kalendarz-cien.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zdjecie11: file(relativePath: { eq: "lista-kalendarz2-cien.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zdjecie12: file(relativePath: { eq: "ofertowanie2-cien.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
zdjecie13: file(relativePath: { eq: "zamowienia-cien.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;
const wsparciesprzedazy = ({ data }) => {
  return (
    <Artykul
      title="Wsparcie sprzedaży – jak CRM może pomóc menedżerom sprzedaży?"
      articleImage={obrazekArtykulu}
      articleImageAlt="Wsparcie sprzedaży – jak CRM może pomóc menedżerom sprzedaży?"
      metaTitle="Wsparcie sprzedaży – jak CRM może pomóc menedżerom sprzedaży?"
      metaDescription="Wsparcie sprzedaży ✅ CRM dla menedżera sprzedaży ⦁ Jak może pomóc? ⦁ Analiza ⦁ Koordynacja ⦁ Relacje z klientami ⦁ Video 2022"
    >
        <Container className="container container--text">
        <p>
        System CRM to narzędzie informatyczne, którego głównym celem jest pomoc 
        przedsiębiorstwom funkcjonującym na rynku w usprawnianiu działań z zakresu 
        zarządzania relacjami z klientem. W związku z&nbsp;tym, z jego możliwości korzystają 
        najczęściej działy handlowe, które posiadają bezpośredni kontakt z&nbsp;kontrahentami 
        i mają największy wpływ na nawiązywanie oraz poszerzanie zakresu współpracy. 
        W praktyce oznacza to, że jednym z ważniejszych obszarów, któremu służy CRM 
        jest <strong>wsparcie sprzedaży</strong>.
        </p>
        <p>
        Menedżer sprzedaży jako osoba zarządzająca całym działem ponosi największą 
        odpowiedzialność za prowadzone działania, a także posiada ogrom pracy do 
        wykonania. Nie tylko dba o&nbsp;relacje z klientami, ale odpowiada za planowanie 
        i koordynację zadań czy też analizę osiąganych wyników. Natłok obowiązków 
        często powoduje, że problemem staje się skuteczność wykonywanych aktywności, 
        a genialne kompetencje kierownika nie są w stanie sprostać coraz większym 
        wymaganiom. To moment, w którym warto zainteresować się wsparciem rozwiązań 
        informatycznych i&nbsp;zastanowić się nad wdrożeniem systemu CRM.
        </p>
        <p style={{
          paddingBottom: 20,}}>
        Przeczytaj poniższy artykuł lub zapoznaj się z materiał wideo, aby dowiedzieć 
        się <strong>jak CRM może pomóc w&nbsp;pracy szefa działu sprzedażowego i jakie 
        elementy systemu powinny być dla niego najistotniejsze.</strong>
        </p>
        <div className="text-block">
        <LiteYouTubeEmbed
          poster="maxresdefault"
          id="Tqg5oPl9jp4"
          title="JAK KORZYSTAĆ z CRM będąc managerem sprzedaży?"
        />
      </div>

        </Container>
    <Container className="container container--text">
      <h2
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      ><strong>Pulpit pracy – bieżące wsparcie sprzedaży?</strong>
      </h2>
      <p>
      Pulpit pracy to funkcja systemu CRM, której zadaniem jest nie tyle wsparcie 
      sprzedaży samej w sobie, ale <strong>pomoc w bieżącym analizowaniu sytuacji 
      przedsiębiorstwa</strong>, co z kolei przekłada się na wyniki osiągane 
      w&nbsp;obszarze sprzedażowym. Istotą mechanizmu jest <strong>możliwość samodzielnego 
      dostosowania elementów</strong> (zakładek oraz poszczególnych widgetów), które 
      mogą znaleźć się na omawianym widoku.
      </p>
      <p>
      W przypadku menedżera sprzedaży, który chce działać w efektywny sposób i być na 
      bieżąco z działaniami prowadzonymi w&nbsp;firmie, układ zakładek może wyglądać 
      następująco:
      </p>
      <p>
      <ul>
      <li><strong>Moje zadania</strong>  – zakładka zawierająca aktywności właściciela pulpitu, która poprzez dobór odpowiednich widgetów umożliwia stałe monitorowanie podstawowych działań – zaplanowanych w&nbsp;kalendarzu zadań, a&nbsp;także celów ustalonych na dany okres.</li>
      </ul>
      </p>
      <Lightbox
        style={{
          maxWidth: 1800,
          margin: "0 auto",
          paddingTop: 10,
        paddingBottom: 0,
        }}
        images={[data.zdjecie1]}
        alts={["Moje zadania"]}
      />
      </Container>
    <Container className="container container--text">
      <p>
      <ul>
      <li><strong>Aktywność handlowców</strong> – miejsce istotne dla menedżera sprzedaży, ponieważ stwarza możliwość śledzenia aktywności podejmowanych przez jego podwładnych. Jest to element kontroli pozwalający na ocenę terminowości realizacji zadań, skrupulatności przy tworzeniu historii relacji z klientem poprzez odpowiednie opisywanie zdarzeń, czy też postępu w dążeniu do osiągania celów sprzedażowych.</li>
      </ul>
      </p>
      <Lightbox
        style={{
          maxWidth: 1800,
          margin: "0 auto",
          paddingTop: 10,
        paddingBottom: 0,
        }}
        images={[data.zdjecie2]}
        alts={["Aktywność handlowców"]}
      />
      <p>
      <ul>
      <li><strong>Szanse sprzedaży</strong></li>
      </ul>
      </p>
      <p>
      <ul>
      <li><strong>Ofertowanie</strong></li>
      </ul>
      </p>
      <p>
      <ul>
      <li><strong>Zamówienia</strong></li>
      </ul>
      </p>
      <p>
      <ul>
      <li><strong>Sprzedaż</strong></li>
      </ul></p>
      <p>
      Chcesz dowiedzieć się więcej o pulpicie pracy? Stworzyliśmy specjalny materiał 
      na ten temat. <Link to="/dashboard-managera/">
              {" "}
              <strong>Zapraszamy do zapoznania się z jego treścią.</strong>
            </Link>
            </p>
      </Container>
      <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Poszukujesz wsparcia sprzedaży prowadzonej przez Twoje przedsiębiorstwo?
</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby uzyskać dostęp do bezpłatnej 30-dniowej wersji systemu CRM


</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i&nbsp;wznieść sprzedaż na wyższy poziom
            </Link>
          </div>
          </div>
    </section>
      <Container className="container container--text">
      <h2
        style={{
          textAlign: "left",
          paddingTop: 0,
          paddingBottom: 20,
        }}
      ><strong>Cała wiedza o&nbsp;kontrahencie w&nbsp;jednym miejscu</strong>
      </h2>
      <p>
      CRM to wsparcie sprzedaży i dbałość o&nbsp;relacje z klientami. Jaki mechanizm może 
      być pomocą dla menedżera w tym aspekcie? Niezwykle istotna jest <strong>spójna 
      i&nbsp;systematycznie tworzona baza kontrahentów</strong>, która zawiera wszystkie 
      te informacje, które są kluczowe z punktu widzenia danej działalności.
      </p>
      <p>
      Baza kontrahentów to lista wszystkich firm (B2B) lub osób (B2C), z którymi 
      została nawiązana relacja, którą należy stale utrzymywać i rozwijać. Szczegółowe 
      dane o&nbsp;każdym kliencie znajdują się natomiast w&nbsp;<strong>dedykowanych kartach 
      kontrahentów</strong>. Taki sposób organizacji sprawia, że zarządzający bazą 
      menedżer sprzedaży z&nbsp;łatwością może dotrzeć do istotnych w&nbsp;danym momencie 
      informacji, co usprawnia proces obsługi interesantów i wpływa na wizerunek 
      przedsiębiorstwa.
      </p>
      </Container>
      <Container className="container container--text">
      <h3
        style={{
          textAlign: "left",
          paddingTop: 0,
          paddingBottom: 0,
        }}
      ><strong>Szczegóły w&nbsp;karcie kontrahenta</strong>
      </h3>
      <p>
      Karty kontrahentów podzielone są na tematyczne zakładki, które jeszcze głębiej 
      porządkują zawarte w nich informacje. Poznaj najistotniejsze z punktu widzenia 
      menedżera sprzedaży.
      </p>
      <p>
      <ul>
      <li><strong>O firmie</strong> – zakładka, która stanowi zbiór podstawowych 
      informacji o firmie (nazwa, NIP, osoby powiązane), a&nbsp;także gromadzi dane kontaktowe, 
      adresowe oraz stanowi źródło wiedzy o elementarnych aspektach zawartej relacji 
      (np. opiekun, forma płatności, rodzaj dostawy, czy grupa cennikowa).
      </li>
      </ul>
      </p>
      <Lightbox
        style={{
          maxWidth: 1800,
          margin: "0 auto",
          paddingTop: 10,
        paddingBottom: 0,
        }}
        images={[data.zdjecie3]}
        alts={["O firmie"]}
      />
      </Container>
    <Container className="container container--text">
      <p>
      <ul>
      <li><strong>Finanse</strong> – miejsce, w którym menedżer sprzedaży może na 
      bieżąco <strong>śledzić rozliczenia powiązane z&nbsp;danym klientem</strong>. Po 
      pierwsze, ma on dostęp do nieuregulowanych należności i&nbsp;zobowiązań, a po 
      drugie – może analizować łączne obroty netto generowane w obecnym i poprzednich 
      miesiącach.
      </li>
      </ul>
      </p>
      <p>
      <ul>
      <li><strong>Dokumenty </strong> – zakładka mająca wpływ na wsparcie sprzedaży 
      poprzez możliwość automatycznego zapisywania i powracania w razie potrzeb do 
      powiązanych z&nbsp;kontrahentem szans sprzedaży, zamówień, ofert handlowych, faktur, 
      płatności oraz dokumentów magazynowych.
      </li>
      </ul>
      </p>
      <p>
      <ul>
      <li><strong>Rabaty</strong> – głównym elementem, który menedżer sprzedaży może 
      sprawdzić w&nbsp;tak zatytułowanej zakładce jest oczywiście <strong>poziom 
      rabatowy</strong> przypisany danemu klientowi. Nie jest to jednak jedyna 
      możliwość  – szef działu handlowego może, zgodnie z potrzebami i&nbsp;ustaleniami 
      z kontrahentem edytować wprowadzony wcześniej rabat, a także dodawać nowe. Dodatkowo, 
      w tym miejscu podejrzeć można <strong>cenniki przypisane do poszczególnych 
      klientów</strong>, które ze względu na indywidualny proces obsługi mogą się nieco różnić.  
      </li>
      </ul>
      </p>
      <p>
      <ul>
      <li><strong>Atrybuty</strong> – zakładka, którą można określić jako 
      miejsce <strong>segmentacji klienta</strong> dokonywanej poprzez 
      przypisywanie mu określonych cech. W związku z różnymi potrzebami przedsiębiorstw 
      i odmiennymi profilami działalności, każdy zespół sprzedażowy na czele 
      z kierownikiem może <strong>samodzielnie określić pola</strong> znajdujące się 
      w niniejszej zakładce.
      </li>
      </ul>
      </p>
      <p>
      <ul>
      <li><strong>Zadania i zdarzenia</strong> – najważniejsza zakładka w kontekście 
      budowania trwałej relacji z klientem. Obejmuje ona zadania, czyli aktywności 
      zaplanowane do zrealizowania oraz zdarzenia, które podsumowują dotychczas wykonane 
      działania. Jest ona niezwykle istotna dla szefów sprzedaży, ponieważ pozwala 
      na bieżąco monitorować pracę handlowców i&nbsp;śledzić ich dokonania względem klientów
      </li>
      </ul>
      </p>
       <Lightbox
        style={{
          maxWidth: 1800,
          margin: "0 auto",
          paddingTop: 10,
        paddingBottom: 0,
        }}
        images={[data.zdjecie5]}
        alts={["Zadania i zdarzenia"]}
      />
      </Container>
     
    <Container className="container container--text">
      <h2
        style={{
          textAlign: "left",
          paddingTop: 0,
          paddingBottom: 20,
        }}
      ><strong>Zarządzanie szansami – skuteczne wsparcie sprzedaży</strong>
      </h2>
      <p>
      Jednym z ważniejszych zadań menedżera sprzedaży jest dbałość o efektywny sposób 
      prowadzenie działań sprzedażowych. W tym aspekcie CRM można określić jako ogromne 
      wsparcie sprzedaży i pomoc dla kierownika, ponieważ stwarza on możliwość analizowania 
      postępów w pozyskiwaniu nowych kontrahentów. Służy temu <strong>moduł szans sprzedaży 
      funkcjonujący w trzech spełniających rożne role widokach.</strong>
      </p>
      <p>
      Pierwszą możliwością jest <strong>klasyczna wersja szans sprzedaży</strong>, która 
      umożliwia ich analizę z uwzględnieniem podziału na etapy procesu sprzedażowego. 
      Taki sposób przedstawienia pozwala menedżerowi na bieżąco monitorować poszczególne 
      szanse obsługiwane przez handlowców i śledzić rozwój potencjalnej współpracy.
      </p>
      <p>
      Dodatkowymi elementami widoku klasycznego są:
      </p>
      <p>
      <ul>
      <li>wartości poszczególnych szans,</li>
      <li>możliwość poznania szczegółów każdej szansy (poprzez dwukrotne kliknięcie w&nbsp;nią),</li>
      <li>informujące o terminowości zadań powiązanych z szansą.</li>
      </ul>
      </p>
       <Lightbox
        style={{
          maxWidth: 1800,
          margin: "0 auto",
          paddingTop: 10,
        paddingBottom: 0,
        }}
        images={[data.zdjecie6]}
        alts={["Szanse"]}
      />
      </Container>
    <Container className="container container--text">
      <p>Jeżeli jako menedżer potrzebujesz dokonać analizy wyników osiągniętych przez 
      Twój zespół i sprawdzić, jak kształtowały się wygrane szanse wraz z ich wartościami 
      na przestrzeni miesięcy, możesz skorzystać z&nbsp;drugiej opcji – <strong>widoku 
      zegara</strong>. To niezwykłe wsparcie sprzedaży w aspekcie odpowiedniego planowania 
      targetów na przyszłość.
      </p>
       <Lightbox
        style={{
          maxWidth: 1800,
          margin: "0 auto",
          paddingTop: 10,
        paddingBottom: 0,
        }}
        images={[data.zdjecie7]}
        alts={["Szanse"]}
      />
      </Container>
    <Container className="container container--text">
      <p>
      Trzecią opcją jest <strong>lista</strong>, która stanowi podsumowanie wszystkich 
      szans – wygranych, przegranych oraz otwartych, które pojawiły się kiedykolwiek 
      w Twoim przedsiębiorstwie. Jako menedżer sprzedaży możesz wykorzystać tę możliwość 
      systemu do głębszej analizy prowadzonych działań poprzez filtrowanie po wybranych 
      aspektach – tytule, statusie, dacie otwarcie, dacie zamknięcia, opiekunie lub 
      źródle pozyskania.
      </p>
       <Lightbox
        style={{
          maxWidth: 1800,
          margin: "0 auto",
          paddingTop: 10,
        paddingBottom: 0,
        }}
        images={[data.zdjecie8]}
        alts={["Szanse"]}
      />
      </Container>
      <Container className="container container--text">
      <h2
        style={{
          textAlign: "left",
          paddingTop: 0,
          paddingBottom: 20,
        }}
      ><strong>Doskonal swój biznes analizując podejmowane działania</strong>
      </h2>
      <p>
      Wsparcie sprzedaży to nie tylko dbanie o&nbsp;aspekt pozyskiwania nowych klientów 
      i&nbsp;budowania relacji z nimi. Celem każdego przedsiębiorstwa powinno być ciągłe 
      doskonalenie oparte na zbieraniu danych, szczegółowej analizie, a następnie 
      podejmowaniu decyzji prowadzących do rozwoju biznesowego. CRM pomaga menedżerowi 
      sprzedaży w tym aspekcie, wyposażając go w różnorodne <strong>raporty 
      systemowe</strong>. Elementami, które warto analizować za pomocą przejrzystych zestawień są:
      </p>
      <p>
      <ul>
      <li>utracone szanse i ich przyczyny,</li>
      <li>aktywność handlowców,</li>
      <li>konwersja lejka i etapy na, których pojawia się najwięcej utrat.</li>
      </ul>
      </p>
       <Lightbox
        style={{
          maxWidth: 1800,
          margin: "0 auto",
          paddingTop: 10,
        paddingBottom: 0,
        }}
        images={[data.zdjecie9]}
        alts={["Raport"]}
      />
      </Container>
    <Container className="container container--text">
      <p>
      Warto podkreślić, że nie sama analiza jest najważniejsza. Istotna jest także 
      umiejętność sformułowania wniosków i przełożenia ich na usprawnienie prowadzonego 
      procesu sprzedażowego. Tylko w taki sposób wsparcie sprzedaży oferowane przez CRM 
      zostanie wykorzystane w pełnym wymiarze.
      </p>
      </Container>
      <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Poszukujesz wsparcia sprzedaży prowadzonej przez Twoje przedsiębiorstwo?

</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby uzyskać dostęp do bezpłatnej 30-dniowej wersji systemu CRM  


</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i&nbsp;wznieść sprzedaż na wyższy poziom
            </Link>
          </div>
          </div>
    </section>

    <Container className="container container--text">
      <h2
        style={{
          textAlign: "left",
          paddingTop: 0,
          paddingBottom: 20,
        }}
      ><strong>Kalendarz i&nbsp;zadania – planowanie i&nbsp;koordynacja</strong>
      </h2>
     <p>
     Inną istotną funkcją CRM-a, która pośrednio wpływa na wsparcie sprzedaży, 
     a&nbsp;bezpośrednio usprawnia codzienną pracę każdego menedżera jest <strong>Kalendarz</strong>. To 
     moduł, który umożliwia sprawne planowania działań poszczególnych handlowców poprzez 
     możliwość przydzielania im zadań. Dodatkowo realizuje on również funkcję 
     kontrolną – kierownik może monitorować plany swoich pracowników i terminowość 
     realizowanych przez nich obowiązków.
     </p>
     <Lightbox
        style={{
          maxWidth: 1800,
          margin: "0 auto",
          paddingTop: 10,
        paddingBottom: 0,
        }}
        images={[data.zdjecie10]}
        alts={["Kalendarz"]}
      />
      </Container>
      <Container className="container container--text">
     <p>
     Kalendarz, podobnie jak moduł szans sprzedaży, posiada więcej niż jeden widok. 
     Drugą możliwością jest wyświetlanie zadań oraz zdarzeń w postaci listy – daje to 
     możliwość zbiorczego analizowania działań podejmowanych przez handlowców wraz 
     z&nbsp;notatkami, które są niezbędnym elementem pracy w systemie CRM.
     </p>
           <Lightbox
        style={{
          maxWidth: 1800,
          margin: "0 auto",
          paddingTop: 10,
        paddingBottom: 0,
        }}
        images={[data.zdjecie11]}
        alts={["Lista"]}
      />
      </Container>
      <Container className="container container--text">
       <h2
        style={{
          textAlign: "left",
          paddingTop: 0,
          paddingBottom: 20,
        }}
      ><strong>Wspieraj sprzedaż tworząc spójne dokumenty</strong>
      </h2>
      <p>
      Na dokumenty w programie CRM składają się:
      </p>
      <p>
      <ul>
      <li>oferty handlowe,</li>
      <li>zamówienia,</li>
      <li>faktury sprzedaży,</li>
      <li>faktury zakupu,</li>
      <li>repozytorium plików.</li>
      </ul>
      </p>
      <p>
      Zaletą CRM-a w tym kontekście jest to, że umożliwia on tworzenie jednolitych 
      wzorów dokumentów. Dlaczego ustandaryzowane dokumenty mają tak ogromne znaczenie 
      w&nbsp;każdym przedsiębiorstwie? Odpowiedź jest prosta – pozwalają zachować spójny 
      sposób obsługi klienta i w ten sposób wpływają na pozytywny wizerunek firmy.
      </p>
      <p> 
      Dodatkowo tworzenie dokumentów w&nbsp;systemie CRM wpływa na wsparcie sprzedaży – pozwala 
      dokonywać cennych analiz, które przyczyniają się do późniejszych usprawnień biznesowych. 
      Przykładowo – generowane w CRM oferty zapisują się w systemie i służą do tworzenia 
      zestawień raportowych określających popularność poszczególnych produktów wśród Twoich 
      klientów.
      </p>
      <Lightbox
        style={{
          maxWidth: 1800,
          margin: "0 auto",
          paddingTop: 10,
        paddingBottom: 0,
        }}
        images={[data.zdjecie12]}
        alts={["Ofertowanie"]}
      />
      </Container>
      <Container className="container container--text">
      <p>
      Pracując jako menedżer sprzedaży warto zwrócić uwagę na jedną z&nbsp;zakładek 
      w&nbsp;Dokumentach, która zatytułowana jest “Zamówienia klienta”. Jest to miejsce, 
      w&nbsp;którym gromadzone są wszystkie pojawiające się zamówienia oraz wszelkie 
      szczegółowe informacje pozwalające na sprawną obsługę kontrahentów i&nbsp;późniejszą 
      ich analizę.
      </p>
      <Lightbox
        style={{
          maxWidth: 1800,
          margin: "0 auto",
          paddingTop: 10,
        paddingBottom: 0,
        }}
        images={[data.zdjecie13]}
        alts={["Zamówienia klienta"]}
      />
      </Container>
      <Container className="container container--text">
      <h2
        style={{
          textAlign: "left",
          paddingTop: 0,
          paddingBottom: 20,
        }}
      ><strong>Zarządzanie uprawnieniami</strong>
      </h2>
      <p>
      Ważnym działaniem podejmowanym przez menedżera sprzedaży w systemie CRM jest 
      zarządzanie uprawnieniami. Aby zapewnić odpowiednie bezpieczeństwo danych 
      przechowywanych w rozwiązaniu informatycznym konieczne jest ograniczenie 
      dostępu poszczególnych funkcji innym pracownikom.
      </p>
      <p>
      W skład opisywanego w tym momencie modułu wchodzi również możliwość śledzenia 
      działań pracowników podejmowanych na ich skrzynkach pocztowych – dzięki temu 
      jako menedżer sprzedaży jesteś na bieżąco z&nbsp;kontaktami z&nbsp;kontrahentami i wiesz, 
      jak przebiega praca handlowców.
      </p>
      </Container>   
      <Container className="container container--text">
      <h2
        style={{
          textAlign: "left",
          paddingTop: 0,
          paddingBottom: 20,
        }}
      ><strong>Podsumowanie</strong>
      </h2>
      <p>
      Praca menedżera sprzedaży jest złożona i&nbsp;wieloaspektowa – musi mierzyć się on 
      z&nbsp;wieloma wyzwaniami i&nbsp;sprawnie funkcjonować w przynajmniej kilku obszarach. 
      W toku rozwoju firmy często zdarza się, że jego kompetencje (mimo, iż są bardzo 
        wysokie) muszą zostać wsparte rozwiązaniem informatycznym – np. CRM-em.
      </p>
      <p>
      Jeżeli znajdujesz się na takim etapie i&nbsp;wsparcie sprzedaży jest Ci potrzebne, 
      skontaktuj się z nami. Porozmawiamy o&nbsp;Twoich potrzebach i dobierzemy rozwiązanie 
      najlepiej dopasowane do Twojej firmy.
      </p>
      </Container>
      <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Poszukujesz wsparcia sprzedaży prowadzonej przez Twoje przedsiębiorstwo?
</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby uzyskać dostęp do bezpłatnej 30-dniowej wersji systemu CRM


</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i&nbsp;wznieść sprzedaż na wyższy poziom
            </Link>
          </div>
          </div>
    </section>       
      <p>
        <strong>Polecamy również:</strong>
        </p>
        <p>
        <ul>
          <li>
            <Link to="/dashboard-managera/">Dashboard managera – jeden rzut oka, by poznać sytuację całej firmy</Link>
          </li>
          <li>
            <Link to="/wdrozenie-crm/">
              {" "}
              Porażki wdrożeń CRM - dlaczego się nie udaje?
            </Link>
          </li>
          <li>
            <Link to="/program-do-tworzenia-ofert-handlowych/">
              {" "}
             Wykorzystaj program do tworzenia ofert handlowych w&nbsp;celu poprawy swoich działań sprzedażowych
            </Link>
          </li>
        </ul>
      </p>
    </Artykul>
  );
};

export default wsparciesprzedazy;
